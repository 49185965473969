/*Intl Phone Number Input ---------------*/

.iti__flag {
    background-image: url('/vendor/intl-tel-input/img/flags.png');
}
.iti {
    width: 100%;
}
.input-icon .form-control:not(:first-child) {
    padding-left: 3.5rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url('/vendor/intl-tel-input/img/flags@2x.png');
    }
}

/* Hero Image -------------------------*/

.hero-img-height {
    height: 32rem;
    background: #e9ecef;
}
.hero-img-cover-top {
    background: no-repeat top;
    background-size: cover;
}
.hero-img-cover-center {
    background: no-repeat center;
    background-size: cover;
}
@media only screen and (max-device-width: 750px) {
    .hero-img-height {
        height: 14rem;
    }
}

/* Scan success ----------------------------*/

.vertical-center {
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.avatar-shadow {
    box-shadow: 0 1px 5px rgba(27, 31, 35, 0.15);
}

.btn-success-scan {
    background-color: #28a745;
    background-image: linear-gradient(-180deg, #34d058, #28a745 90%);
    color: #fff;
}
.btn-success-scan:hover {
    color: #fff;
    background-color: #269f42;
    background-image: linear-gradient(-180deg, #2fcb53, #269f42 90%);
    background-position: -0.5em;
    border-color: rgba(27, 31, 35, 0.5);
}
.bg-success-scan {
    background-color: #28a745;
}
.btn-outline-success-scan {
    color: #28a745;
    border-color: #28a745;
}
.btn-outline-success-scan:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-md {
    padding: 0.5rem;
    font-weight: 400;
}

.DashedConnection:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    content: '';
    border-bottom: 2px dashed #e1e4e8;
}

.DashedConnection {
    position: relative;
}

.flex-justify-between {
    justify-content: space-between !important;
}

.flex-items-center {
    align-items: center !important;
}

/* Nav Scroll -------------------------*/
.scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.flex-scroll {
    display: flex;
    flex-wrap: nowrap;
}
.example-two-header .logo {
    width: 25%;
}
.example-two-header nav {
    width: 75%;
}

.nav-tabs .nav-item {
    margin-bottom: 0px;
}
body *::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    transition: 0.3s background;
}
body *::-webkit-scrollbar-thumb {
    background: transparent;
}
body {
    background-color: white;
}
/* -------------------------------------*/

.tab-content > .tab-pane {
    display: block;
    height: 0px;
    overflow: hidden;
}
.tab-content > .active {
    height: auto;
}

/*-------------------------------------*/
footer a:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #2a3742;
}

.card-footer {
    background-color: rgba(255, 255, 255, 0.024);
}

.nav-tabs .nav-link.active {
    border-bottom-color: #2a3742;
}

@media only screen and (max-device-width: 750px) {
    .navbar-secondary-blue {
        background: #63c6e1;
    }
    .navbar-secondary-blue .navbar-toggler {
        color: rgba(0, 0, 0, 1);
        border-color: #63c6e1;
    }
    .navbar-secondary-blue .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 1);
        font-weight: 800;
        border: solid 1px #4a91a5;
        border-width: 1px 0px 0px 0px;
    }
    .navbar-secondary-blue .navbar-nav .nav-item:last-child .nav-link {
        background: #000000;
        color: white;
    }
}

.navbar-dark {
    background: #2a3742;
    color: #fff;
    box-shadow: 0 0 0 1px #2f3949;
}
.bg-dark {
    background: #2a3742 !important;
}
a {
    color: #7799b5;
    text-decoration: none;
}
.btn-primary {
    color: #fff;
    background-color: #2a3742;
    border-color: #2a3742;
}
.btn-primary:hover {
    color: #fff;
    background-color: #4a5f71;
    border-color: #1e282f;
}
.text-standard {
    color: #2a3742;
}
.alert-standard {
    color: white;
    background-color: #465a6b;
    border-color: #2a3742;
}
.btn-outline-standard {
    color: #cfd7e6;
    border-color: #868c97;
}

.dropdown-menu-overflow {
    position: fixed;
    top: auto;
    left: auto;
}

.card-hover:hover {
    text-decoration: none;
    box-shadow: rgba(53, 64, 82, 0.16) 0 2px 16px 0;
}

.qr-logo-overlay {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    vertical-align: middle;
    width: 23%;
}
.news-image {
    position: relative;
    width: 8rem;
    height: 6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: bottom;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #f1f3f8 no-repeat center/cover;
    border-radius: 3px;
}

#qrcode img {
    margin: 0 auto;
}

/*
============================== Button styling ==============================
*/
.btn-loading-dark {
    position: relative;
    color: transparent !important;
    text-shadow: none !important;
    pointer-events: none;
}
.btn-loading-dark:after {
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    border: 1px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    color: #1b1b1b;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}

/*
===========================================================================
*/
.dimmer-notice {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    width: 220px;
    /* height: 2.5rem; */
    color: #206bc4;
    vertical-align: middle;
}
.dimmer.active .dimmer-content {
    pointer-events: none;
    opacity: 0.02;
}
.nav-tabs {
    border-bottom: 0px solid #e9ecf1;
}
.nav-tabs .nav-link {
    padding: 1.1rem 1.25rem;
}
.tab-content .tab-pane {
    position: relative;
}
.forMobile {
    display: none;
}
.reveal-code-alt {
    cursor: pointer;
}

a:hover i,
a,
i {
    text-decoration: none !important;
}

a {
    color: rgb(95, 95, 95);
}

a:hover {
    color: black;
}

/*
===============================================mobile controller===============================================
*/
@media only screen and (max-device-width: 750px) {
    .forMobile {
        display: block;
    }
    .navbar-mobile {
        padding: 0px;
    }
    .forScreen {
        display: none;
    }
    .w-md-100 {
        width: 100% !important;
    }
    .navbar:not(.navbar-vertical) .dropdown-menu {
        margin-right: 0rem;
    }
}

@media only screen and (min-device-width: 750px) {
    .forScreen {
        display: block;
    }
    .w-lg-30 {
        width: 30% !important;
    }
}
