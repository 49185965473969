.header {
    background: #1b2935;
    color: #7cddf8;
    text-align: center;
    margin: 1% 8%;
    padding: 1%;
}

.spaced {
    margin: 1% 10%;
}

.gap-b {
    margin-bottom: 1%;
}

.small-title {
    font-size: 1.5vw;
}

.small-sub {
    font-size: .75vw;
}

@media screen and (max-width: 1300px) {
  .small-sub {
    font-size: 1.5vw;
  }
  .small-title {
    font-size: 3vw;
  }
}


h1 {
    font-size: 225%;
}